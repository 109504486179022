export const AUTH_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const ID_TOKEN = "idToken";

export const isVehicleOwner = (userProfile: any) => {
  return userProfile?.user?.roles[0]?.roleCode === "VEHICLE_OWNER";
};
export const isShipper = (userProfile: any) => {
  return userProfile?.user?.roles[0]?.roleCode === "SHIPPER";
};
