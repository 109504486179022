import React, { CSSProperties, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Col, Layout, Modal, notification, Row, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useMobile } from "../hooks/useMobile";
import { ContentWidthProvider } from "./ContainerWithProvider";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { userThunks } from "user/presentation/store/userSlice";
import { typography } from "theme/Typography";
import { color } from "theme/Color";
import Loader from "../components/Loader";
import AButton from "../components/Button";
import { fleetThunks } from "fleet/presentation/store/fleetSlice";
import { orderThunks } from "order/presentation/store/orderSlice";
import { notificationThunks } from "notifications/presentation/store/notificationSlice";

const MainLayout = ({ children, renderSidebar }: any): JSX.Element => {
  const isMobile = useMobile();
  const dispatch = useAppDispatch();

  const { showHeader, userProfile } = useAppSelector((state) => state.user);
  const { shipperBids, orders, isLoading, error, digitalContract } =
    useAppSelector((state) => state.order);

  const [collapsed, setCollapsed] = useState(!isMobile);
  const [contractModal, setContractModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const bidRequestId = 10;
  useEffect(() => {
    dispatch(notificationThunks.getNotificationThunk({ page: 1 }));
  }, []);
  
  useEffect(() => {
    const toggleHeader = async (shouldShow: boolean) => {
      await dispatch(userThunks.showHeader(shouldShow));
    };

    const hasDigitalContract = userProfile?.user?.digitalContract?.length > 0;

    toggleHeader(!!hasDigitalContract);
  }, [dispatch, userProfile]);

  const handleHeader = async (value: boolean) => {
    await dispatch(userThunks.showHeader(value));
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const contentCSS: CSSProperties = {
    transition: "ease-in-out",
    transitionDuration: "0.2s",
    display: "flex",
    flex: 1,
    minHeight: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const handleClick = async () => {
    await dispatch(
      orderThunks.getDigitalContractWithBidRequest(
        userProfile?.user?.digitalContract[0]?.bidRequestId
      )
    );

    setContractModal(true);
  };

  const handleAcknowledge = async () => {
    setLoading(true);

    const data: any = {
      bidRequestId: userProfile?.user?.digitalContract[0]?.bidRequestId,
      contract: digitalContract?.template,
    };

    const { payload } = await dispatch(
      fleetThunks.acknowledgeContractThunk(
        userProfile?.user?.digitalContract[0]?.bidRequestId
      )
    );

    if (payload?.data?.success) {
      setLoading(false);
      setContractModal(false);
      handleHeader(false);
      notification.success({
        message: "Success",
        description: "Contract Acknowledged Successfully!",
      });
    } else {
      setLoading(false);

      notification.error({
        message: "Error",
        description: "Failed to Acknowledge Contract , Please try again..",
      });
    }
  };

  return (
    <>
      {showHeader && (
        <Header style={headerStyle}>
          <div>
            You have a pending acknowledgment to acknowledge, please agree to
            the contract to complete the order process.{" "}
            <a
              onClick={handleClick}
              style={{ color: color.PRIMARY, fontWeight: 700 }}
            >
              Click here to view Digital Contract.
            </a>
          </div>
        </Header>
      )}
      <Layout style={{ display: "flex", flex: 1 }}>
        {renderSidebar && collapsed && (
          <Sidebar collapsed={!collapsed} setCollapsed={setCollapsed} />
        )}
        <Layout style={{ display: "flex", flex: 1 }}>
          <Content style={{ ...contentCSS }}>
            {renderSidebar && !collapsed && (
              <div style={{ position: "relative" }}>
                <MenuOutlined
                  onClick={() => setCollapsed(true)}
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "35px",
                    zIndex: 1,
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            <Content
              id="main-content"
              style={{
                margin: "0 1rem 0rem 1rem",
                display: "flex",
                flex: 1,
                maxWidth: "100%",
              }}
            >
              <ContentWidthProvider>{children}</ContentWidthProvider>
            </Content>
          </Content>
        </Layout>
      </Layout>

      <Modal
        open={contractModal}
        onCancel={() => setContractModal(false)}
        width={"90%"}
        closeIcon={<CloseOutlined />}
        footer={null}
        centered
        style={{ maxHeight: "80vh", maxWidth: "60vw" }} // Set a maximum height for the modal
      >
        <div className="modal-body">
          <p
            style={{
              fontSize: typography.fontSizes.lg,
              fontWeight: typography.fontWeights.semibold,
              lineHeight: "28px",
              color: color.TEXT_COLOR,
            }}
          >
            Digital Contract
          </p>
          {digitalContract ? (
            <p
              style={{
                fontSize: typography.fontSizes.sm,
                fontWeight: typography.fontWeights.normal,
                lineHeight: "20px",
                color: color.TEXT,
              }}
              dangerouslySetInnerHTML={{ __html: digitalContract?.template }}
            />
          ) : (
            <Loader />
          )}
        </div>
        <Row gutter={14} style={{ marginTop: "30px" }}>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <AButton
              type="text"
              mode="darkoutlined"
              onClick={() => setContractModal(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              onClick={() => {
                handleAcknowledge();
                setContractModal(false);
              }}
            >
              Acknowledge
            </AButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MainLayout;

const headerStyle: React.CSSProperties = {
  height: "auto",
  padding: "10px 20px",
  backgroundColor: "#ffffff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  lineHeight: 1,
};
