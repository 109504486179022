import React, { useState } from "react";
import { Typography } from "antd";
import "../../styles/Terms&Conditions.css";
import { useNavigate } from "react-router-dom";
import { userThunks } from "../../store/userSlice";
import AButton from "core/presentation/components/Button";
import { SuccessTickIcon } from "../../../../assets/icons/SuccessTick";
import ATitle from "core/presentation/components/Title";
import FailedModal from "core/presentation/components/FailedModal";
import locales from "core/presentation/i18n/locales";
import { styles } from "./style";
import { jwtDecode } from "jwt-decode";
import { JwtTokens } from "user/business/User";
import { getRoleCode } from "core/presentation/utils/Helper";

import SuccessModal from "core/presentation/components/SuccessModal";
import { useAppSelector } from "core/presentation/store/hooks";

import Welcome from "../Welcome";
const { Text } = Typography;

const TermsandConditions: React.FC<{
  onClose: () => void;
  contact?: number;
  onSuccess?: () => void;
}> = ({ onClose, contact, onSuccess }) => {
  const roleCode = getRoleCode();

  const navigate = useNavigate();
  const { userProfile } = useAppSelector((state) => state.user);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const isFirstLogin = userProfile?.user?.isFirstLogin;

  const handleAgreeTerms = async () => {
    try {
      const response = await userThunks.acceptTermsAndCondition();
      if (response.data.success) {
        // isFirstLogin is false in first login and true in subsequent login
        if (onSuccess && !!!isFirstLogin) {
          // isFirstLogin is false in first login and true in subsequent login
          onSuccess();
          return;
        }
        if (roleCode === "VEHICLE_OWNER") {
          onClose();
          setShowSuccessModal(true);
        } else {
          navigate("/");
        }
      } else {
        setShowFailedModal(true);
      }
    } catch (error) {
      setShowFailedModal(true);
      console.error("Issue on Accept Terms and Conditions", error);
    }
  };

  const handleRetry = () => {
    setShowFailedModal(true);
  };
  return (
    <>
      <SuccessTickIcon />
      <ATitle
        className="term-title"
        content={locales.en.auth.pages.TermsandConditions.TermsandConditions}
        style={styles.TermTitle}
      ></ATitle>
      {/* <Text className="term-text">
        {locales.en.auth.pages.TermsandConditions.lastRevised}
      </Text>
      <br />
      <br /> */}
      <div style={{ maxHeight: "450px", overflowY: "auto" }}>
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.welcomeMessage}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFirstLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFirstLine_First}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFirstLine_FirstDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFirstLine_Second}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFirstLine_SecondDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSecondLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSecondLine_First}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSecondLine_FirstDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSecondLine_Second}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSecondLine_SecondDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSecondLine_Third}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSecondLine_ThirdDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_First}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_FirstDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_Second}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_SecondDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_SecondDescription_Second
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_Third}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_ThirdDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_Fourth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_TFourthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_Fifth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_FifthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_Sixth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_SixthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine_Seven}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLine_SevenDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_First}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_FirstDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Second}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_SecondDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Third}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_ThirdDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Fourth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_FourthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Fifth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_FifthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Sixth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_SixthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Seventh}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_SeventhDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Eighth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_EighthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Ninth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_NinthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine_Tenth}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLine_TenthDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFifthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFifthLine_First}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFifthLine_FirstDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFifthLine_Second}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFifthLine_SecondDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSixthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSixthLine_First}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSixthLine_FirstDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSixthLine_Second}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSixthLine_SecondDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSeventhLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSeventhLine_Description_First
          }
          <a href="https://test.lodbod.com/privacy-policy">
            {" "}
            https://test.lodbod.com/privacy-policy.
          </a>
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSeventhLine_Description_Second
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementEighthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementEighthLine_Description
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementNinthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementNinthLine_Description
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementAcceptanceLine}
        </Text>
        {/* <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSecondLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSecondLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFifthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFifthLineDescriptionA
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFifthLineDescriptionB
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementSixthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSixthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementSeventhLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSeventhLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementEighthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementEighthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementNinthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementNinthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementTenthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementTenthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementEleventhLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementEleventhLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementTwelfthLine}
        </Text>
        <br />
        <br /> */}
        {/* <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementTwelfthLineDescription
          }
        </Text> */}
      </div>
      <br />

      <AButton value="large" onClick={handleRetry} style={styles.DeclineButton}>
        {locales.en.auth.pages.TermsandConditions.Decline}
      </AButton>
      <AButton
        type="primary"
        value="large"
        onClick={handleAgreeTerms}
        style={styles.AcceptButton}
      >
        {locales.en.auth.pages.TermsandConditions.Accept}
      </AButton>
      <FailedModal
        show={showFailedModal}
        onClose={() => setShowFailedModal(false)}
        failedMessage={locales.en.failedModalTexts.TermsandConditionsDeclined}
        additionalText={
          locales.en.failedModalTexts.TermsandConditionsDeclinedSub
        }
        continueButtonText={locales.en.failedModalTexts.TryAgain}
      />
      <SuccessModal
        show={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          setShowWelcomeModal(true);
        }}
        successMessage="Account Creation Successful"
        additionalText="Congratulations! Your account has been successfully created."
        continueButtonText={"Continue"}
      />
      <Welcome
        showWelcomeModal={showWelcomeModal}
        onClose={() => {
          setShowWelcomeModal(false);
          navigate("/additional-information-vehicle-owner");
        }}
      />
    </>
  );
};

export default TermsandConditions;
