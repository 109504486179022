import { VehicleInfo } from "fleet/business/Fleet";
import User, { VehicleTypeBasedInterface, VehicleTypeFeeRule } from "./User";
import { deleteVehicleTypeRuleThunk } from "user/presentation/store/userSlice";

export const IUserRepositoryToken = Symbol();

export interface IUserRepository {
  signup: (values: any) => Promise<any>;
  vehicleOwnerSignup: (values: any) => Promise<any>;
  createPlatformAdmin: (values: any) => Promise<any>;
  getAllPlatformAdmin: (
    page: number,
    input: string,
    filter: string
  ) => Promise<any>;
  updatePlatformAdmin: (id: any, values: any) => Promise<any>;
  getAPlatformAdmin: (id: any) => Promise<any>;
  deletePlatformAdmin: (id: any) => Promise<any>;
  verifyEmail: (values: any) => Promise<any>;
  verifyNumber: (values: any) => Promise<any>;
  verifyUsername: (values: any) => Promise<any>;
  resetPassword: (values: any) => Promise<any>;
  sendCode: (values: any) => Promise<any>;
  setPassword: (values: any) => Promise<any>;
  createOtp: (values: any) => Promise<any>;
  updateAuthMethod: (values: any) => Promise<any>;
  setIsFirstLogin: (values: any) => Promise<any>;
  loginWithPassword: (values: any) => Promise<any>;
  reverseGeoCode: (values: any) => Promise<any>;
  login: (values: any) => Promise<User>;
  logout: (id: any, values: any) => Promise<any>;
  getRefreshToken: () => Promise<any>;
  acceptTerms: () => Promise<any>;
  setAuthUserPassword: (values: any) => Promise<any>;
  acceptTermsWithoutToken: (values: any) => Promise<any>;

  // User Profile
  getUserProfile: () => Promise<any>;
  additonalInfoVehicleOwner: (values: any, id: number) => Promise<any>;
  updateUserProfile: (values: any) => Promise<any>;
  getAdministrativeDivision: () => Promise<any>;
  updatePassword: (values: any) => Promise<any>;
  getShipper: () => Promise<any>;

  // Users

  // Driver
  createDriver: (values: any) => Promise<any>;
  updateDriver: (id: any, values: any) => Promise<any>;
  getAllDriver: (
    page: number,
    input: string,
    filter: string,
    report: string
  ) => Promise<any>;
  getADriver: (id: any) => Promise<any>;
  deleteDriver: (id: any) => Promise<any>;
  // Shipper
  createShipper: (values: any) => Promise<any>;
  updateShipper: (id: any, values: any) => Promise<any>;
  getAllShipper: (page: number, input: string, filter: string) => Promise<any>;
  getAShipper: (id: any) => Promise<any>;
  deleteShipper: (id: any) => Promise<any>;
  // Vehicle Owner
  getAllVechileOwner: () => Promise<any>;
  getAllNewVechileOwner: (
    page: number,
    input: string,
    filter: string,
    report: string
  ) => Promise<any>;
  createVechileOwner: (values: any) => Promise<any>;
  updateVechileOwner: (id: any, values: any) => Promise<any>;
  getAVechileOwner: (id: any) => Promise<any>;
  getVehiclesOfVehicleOwner: (
    contactNumber: number,
    page: number
  ) => Promise<any>;
  deleteVechileOwner: (id: any) => Promise<any>;
  getNoOfVehicles: () => Promise<any>;

  getAllDriverDevices: () => Promise<any>;
  getAllRolesList: (
    page: number,
    input: string,
    filter: string
  ) => Promise<any>;
  getAllPermissionsList: () => Promise<any>;
  getRolePermission: (id: any) => Promise<any>;
  assignPermissions: (values: any) => Promise<any>;
  getAllDeviceLocation: (tripId: any) => Promise<any>;

  //hash
  getHashKey: () => Promise<any>;
  generateNewHash: () => Promise<any>;

  //digital contract
  getDigitalContract: () => Promise<any>;
  generateDigitalContract: (value: any) => Promise<any>;
  assignDriver: (values: any) => Promise<VehicleInfo>;

  // Transaction fees

  // DistanceBased fee
  getAllDistanceBasedFee: () => Promise<any>;
  addDistanceBasedFee: (values: any) => Promise<any>;
  editDistanceBasedFee: (values: any) => Promise<any>;
  deleteAllDistanceBasedFee: () => Promise<any>;
  switchDistanceBasedFee: (value: any) => Promise<any>;

  // vehicle type based fee
  getVehicleTypeBasedRule: () => Promise<any>;
  updateVehicleTypeBasedRule: (value: any) => Promise<any>;
  deleteVehicleTypeRule: (id: number) => Promise<VehicleTypeFeeRule>;

  // percentage based fee
  getPercentageBasedFee: () => Promise<any>;
  addPercentageBasedFee: (values: any) => Promise<any>;
  switchPercentageBasedFee: (value: any) => Promise<any>;

  // Incremental based fee
  getIncrementalBasedFee: () => Promise<any>;
  addIncrementalBasedFee: (values: any) => Promise<any>;
  switchIncrementalBasedFee: (value: any) => Promise<any>;

  // Speed
  getSpeedData: () => Promise<any>;
  addMaxSpeed: (values: any) => Promise<any>;

  //Dahsboard
  getDashboard: (
    role: "ROLE_VEHICLE_OWNER" | "ROLE_PLATFORM" | "ROLE_SHIPPER" | "ROLE_OTHER"
  ) => Promise<any>;

  //post roles
  addNewRoles: (values: any) => Promise<any>;
  //delete role
  deleteRole: (id: number) => Promise<any>;
  getShipmentTrackingReport: (orderNumber: any) => Promise<any>;
  getShipmentCalendarReport: (date: string) => Promise<any>;
  getShipmentDispatchReport: (
    page: number,
    input: string,
    filter: string
  ) => Promise<any>;

  //skipped additional information
  getSkippedAdditionalInfo: () => Promise<any>;

  setEstimatedPriceRule: (value: any) => Promise<any>;
  getEstimatedPriceRule: () => Promise<any>;
  updateEstimatedPriceRule: () => Promise<any>;
}
