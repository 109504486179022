import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/presentation/store";
import { UserSliceState } from "./types";
import { userModuleContainer } from "../../UserModule";
import { AuthStorage } from "../../../core/presentation/services/AuthTokenService";
import User, {
  UpdateShipperPayload,
  UpdateVehicleOwnerPayload,
  VehicleTypeBasedInterface,
} from "user/business/User";
import UserUseCase from "user/application/UserUseCase";
import { UpdateDriverPayload } from "fleet/business/Fleet";
import { FleetModule, fleetModuleContainer } from "fleet/FleetModule";
export interface Logout {
  id: any;
  values: any;
}
const initialState: UserSliceState = {
  isLoading: false,
  platformAdmin: [],
  platformAdminDetails: [],
  drivers: [],
  vehicleOwner: [],
  numberOfVehicles: [],
  shippersCount: 0,
  speed: {},
  distanceBasedFee: {
    isActive: false,
    rules: [],
  },
  percentageBasedFee: {
    isActive: false,
    rules: [],
  },
  incrementalBasedFee: {
    isActive: false,
    rules: [],
  },
  devices: [],
  locations: [],
  vehicleOwnerDetails: null,
  vehicleTypeBasedFee: {
    isActive: false,
    rules: [],
  },
  shippers: [],
  roles: [],
  rolesCount: 0,
  rolePermission: {},
  permissions: [],
  shippersDetails: [],
  usersCount: 0,
  vehicleOwnerCount: 0,
  error: null,
  token: "",
  userProfile: {
    userId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    contactNumber: 0,
    loginMethod: "",
    roles: [],
    permissions: [],
    address: {
      addressId: 0,
      streetAddress: "",
      administrativeDivision: {
        administrativeDivisionId: 0,
        state: "",
        district: "",
        municipality: "",
      },
    },
  },
  user: {
    userId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    contactNumber: 0,
    loginMethod: "",
    verificationCode: { value: 0 },
    username: "",
    accountStatus: "",
    roles: [],
    permissions: [],
    tcAgreed: false,
    address: {
      addressId: 0,
      streetAddress: "",
      administrativeDivision: {
        administrativeDivisionId: 0,
        state: "",
        district: "",
        municipality: "",
      },
    },
  },
  administrativeDivision: [],
  isLoggedIn: false,
  hashKey: "",
  digitalContract: "",
  dashboardData: {},
  driverCount: 0,
  showHeader: false,
  estimatedRule: "",
  shipmentDispatchReport: [],
};

export const updateTokenThunk = createAsyncThunk<
  null,
  any,
  { state: RootState }
>("user/update-token", async (data: any) => {
  await AuthStorage.setSession(data);
  return data;
});

export const createOtpThunk = createAsyncThunk<
  User,
  number,
  { state: RootState }
>("user/createotp", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.createOtp(values);
});

export const userSignUp = createAsyncThunk<any, any, { state: RootState }>(
  "user/register",
  async (data: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = useCase.signup(data);
    return response;
  }
);

export const vehicleOwnerSignUp = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("user/register-vehicle-owner", async (data: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = useCase.vehicleOwnerSignup(data);
  return response;
});

export const updateUserThunk = createAsyncThunk<
  User | any,
  any,
  { state: RootState }
>("user/update-user", async (data: any) => {
  return data;
});

export const userVerifyEmail = createAsyncThunk<any, any, { state: RootState }>(
  "user/verifyEmail",
  async (data: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = useCase.verifyEmail(data);
    return response;
  }
);

const verifyNumbrThunk = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.verifyNumber(values);
    return data;
  } catch (error) {
    return error;
  }
};

export const sendCode = createAsyncThunk<any, any, { state: RootState }>(
  "user/sendCode",
  async (data: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = useCase.sendCode(data);
    return response;
  }
);
const verifyUsername = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.verifyUsername(values);
    return data;
  } catch (error) {
    return error;
  }
};

const resetPassword = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.resetPassword(values);
    return data;
  } catch (error) {
    return error;
  }
};

const reverseGeoCode = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.reverseGeoCode(values);
    return data;
  } catch (error) {
    return error;
  }
};

export const userLoginThunk = createAsyncThunk<any, any, { state: RootState }>(
  "user/userlogin",
  async (data: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = useCase.login(data);
    return response;
  }
);

const acceptTermsAndCondition = async (): Promise<null | any> => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    return await useCase.acceptTerms();
  } catch (error) {
    return error;
  }
};
const acceptTermsAndConditionWithoutToken = async (
  values: any
): Promise<null | any> => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    return await useCase.acceptTermsWithoutToken(values);
  } catch (error) {
    return error;
  }
};
const userLoginWitPasswordThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("user/userloginWithPassword", async (data: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.loginWithPassword(data);
  await AuthStorage.setSession(response?.data?.data);
  return response;
});

export const getRefreshTokenThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("user/getrefreshToken", async (any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getRefreshToken();

  await AuthStorage.setSession(response?.data?.data);
  return response;
});

export const setUserPassword = createAsyncThunk<any, any, { state: RootState }>(
  "user/setUserPassword",
  async (data: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = useCase.setPassword(data);
    return response;
  }
);

export const setAuthUserPasswordThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("user/setAuthUserPasswordThunk", async (data: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = useCase.setAuthUserPassword(data);
  return response;
});

const updateAuthenticationMethod = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    return await useCase.updateAuthMethod(values);
  } catch (error) {
    return error;
  }
};

const setFirstLogin = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    return await useCase.updateIsFirstLogin(values);
  } catch (error) {
    return error;
  }
};

const setIsLoggedIn = createAction<boolean>("user/setIsLoggedIn");

const showHeader = createAction<boolean>("user/show-headert");

export const createPlatformAdminThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("user/createPlatformAdmin", async (data: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = useCase.createPlatformAdmin(data);

  return response;
});

export const getAllPlatformAdminThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("users/platformAdmins", async ({ page, input, filter }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllPlatformAdmin(page, input, filter);
  return response;
});

export const getNoOfVehiclesThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/numberOfVehicles", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getNoOfVehicles();
  return response;
});

export const getAPlatformAdminThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/platform-admin-details", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAPlatformAdmin(id);
  return response;
});

export const updateAuthorizedBidThunk = createAsyncThunk<
  any,
  { state: RootState }
>("fleet/assign-driver-to-vehicle", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.assignDriver(values);
});

export const updatePlatformAdminThunk = createAsyncThunk<
  any,
  UpdateVehicleOwnerPayload,
  { state: RootState }
>("users/update-platform-admin", async ({ id, values }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.updatePlatformAdmin(id, values);
  return response.data;
});
export const deletePlatformAdminThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/delete-platform-admin", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.deletePlatformAdmin(id);
  return response.data;
});

export const getUserProfileThunk = createAsyncThunk<any>(
  "users/currentUser",
  async () => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    return await useCase.getUserProfile();
  }
);
export const updateUserProfileThunk = createAsyncThunk<
  User | any,
  any,
  { state: RootState }
>("users/profile", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.updateUserProfile(values);
});

export const additonalInfoVehicleOwnerThunk = createAsyncThunk<
  User | any,
  any,
  { state: RootState }
>(
  "users/profile-vehicle-owner",
  async ({ values, id }: { values: any; id: number }) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    return await useCase.additonalInfoVehicleOwner(values, id);
  }
);

export const getAdministrativeDivisionThunk = createAsyncThunk<any>(
  "administrativeDivision",
  async () => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    return await useCase.getAdministrativeDivision();
  }
);

const updatePassword = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.updatePassword(values);
    return data;
  } catch (error) {
    return error;
  }
};

// export const userLogout = createAsyncThunk<any, void, { state: RootState }>(
//   "user/userLogout",
//   async (any) => {
//     return await AuthStorage.removeSession();
//   }
// );

export const messageReceivedFromSocketThunk = createAsyncThunk<
  any,
  { state: RootState }
>("socket/message-received", async (values: any) => {
  return;
});

export const userLogout = createAsyncThunk<
  any,
  { id: any; isLogin: boolean },
  { state: RootState }
>("user/userLogout", async ({ id, isLogin }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.logout(id, { isLogin: isLogin });

  if (response.data.success == true) {
    return await AuthStorage.removeSession();
  } else {
    return console.log("logout failed");
  }
});

const getShipperThunk = async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.getShipper();
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllPermissionsThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/permissions", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllPermissionsList();
  return response;
});

export const getAllRolesListThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("users/roles", async ({ page, input, filter }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllRolesList(page, input, filter);
  return response;
});

export const getRolePermissionThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/role-permissions", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getRolePermission(id);
  return response;
});

const assignPermissionsThunk = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.assignPermissions(values);
    return data;
  } catch (error) {
    return error;
  }
};

const getAllDeviceLocationThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/device-location", async (tripId: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);

  return await useCase.getAllDeviceLocation(tripId);
});

// Vehicle Owner
export const getAllVechileOwnerThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("auth/vehicle-owners", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllVechileOwner();
  return response;
});

export const getAllNewVehicleOwnerThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string; report?: string },
  { state: RootState }
>("users/vehicle-owners", async ({ page, input, filter, report }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllNewVechileOwner(
    page,
    input,
    filter,
    report ?? ""
  );
  return response;
});

// const createVechileOwnerThunk = createAsyncThunk<
//   any,
//   any,
//   { state: RootState }
// >("users/vehicle-owners", async (values: any) => {
//   const useCase = userModuleContainer.getProvided(UserUseCase);
//   return await useCase.createVechileOwner(values);
// });

const createVechileOwnerThunk = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.createVechileOwner(values);
    return data;
  } catch (error) {
    return error;
  }
};

export const getAVechileOwnerThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/vehicle-owners-details", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAVechileOwner(id);
  return response;
});

export const getVehiclesOfVehicleOwnerThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "users/vehicle-owners-vehicles-details",
  async ({ contactNumber: contactNumber, page: page }) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = await useCase.getVehiclesOfVehicleOwner(
      contactNumber,
      page
    );
    return response;
  }
);

export const updateVechileOwnerThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/update-vehicle-owners", async ({ id, values }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.updateVechileOwner(id, values);
  return response.data;
});

export const deleteVechileOwnerThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/delete-vehicle-owners", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.deleteVechileOwner(id);
  return response.data;
});

// Driver

export const getAllDriverThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/drivers", async ({ page, input, filter, report }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllDriver(
    page,
    input,
    filter,
    report ?? ""
  );
  return response;
});

const createDriverThunk = createAsyncThunk<any, any, { state: RootState }>(
  "users/driver",
  async (values: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    return await useCase.createDriver(values);
  }
);
export const getADriverThunk = createAsyncThunk<any, any, { state: RootState }>(
  "users/driver",
  async (id: any) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = await useCase.getADriver(id);
    return response;
  }
);

export const updateDriverThunk = createAsyncThunk<
  any,
  UpdateDriverPayload,
  { state: RootState }
>("users/update-driver", async ({ id, values }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.updateDriver(id, values);
  return response.data;
});

export const deleteDriverThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/delete-driver", async (id: any, { rejectWithValue }) => {
  try {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = await useCase.deleteDriver(id);
    if (!response.data.success) {
      return rejectWithValue(response.data);
    }

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

// Shipper

const createShipperThunk = async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  try {
    const data = await useCase.createShipper(values);
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllShipperThunk = createAsyncThunk<
  any,
  { page?: number; input?: string; filter?: string },
  { state: RootState }
>("users/shipper", async ({ page, input, filter }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllShipper(
    page ?? 0,
    input ?? "",
    filter ?? ""
  );
  return response;
});

export const getAShipperThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/shipper-details", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAShipper(id);
  return response;
});

export const updateShipperThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/update-shipper", async ({ id, values }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.updateShipper(id, values);
  return response.data;
});

export const deleteShipperThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/delete-shipper", async (id: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.deleteShipper(id);
  return response.data;
});

export const getAllDriverDevices = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/driver-devices", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllDriverDevices();
  return response;
});

//hash key
export const getHashKey = createAsyncThunk<any, void, { state: RootState }>(
  "users/hashkey",
  async () => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = await useCase.getHashKey();
    return response;
  }
);

export const generateNewHashKey = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/generateNewHashKey", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.generateNewHash();
});

//Digital COntract key
export const getDigitalContract = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/getDigitalContract", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getDigitalContract();
  return response;
});

const generateDigitalContract = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/generateDigitalContract", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.generateDigitalContract(values);
});

// Transaction Fee

// Distance based
export const getAllDistanceBasedFeeDataThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/get-all-distanceBasedFee-data", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getAllDistanceBasedFee();
  return response;
});

export const getAllVehicleTypeBasedFeeDataThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/getAll-vehicleTypeRule", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getVehicleTypeBasedRule();
  return response;
});

export const updateVehicleTypeBasedFeeDataThunk = createAsyncThunk<
  any,
  { state: RootState }
>("users/add-vehicleTypeRule", async (value: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.updateVehicleTypeBasedRule(value);
  return response;
});

export const deleteVehicleTypeRuleThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/delete-VehicleType", async (id: number) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.deleteVehicleTypeRule(id);
  return response;
});

export const addDistanceBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/post-DistanceBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.addDistanceBasedFee(values);
});

export const editDistanceBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/edit-DistanceBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.editDistanceBasedFee(values);
});

export const deleteAllDistanceBasedFeeDataThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/delete-DistanceBased-fee", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.deleteAllDistanceBasedFee();
});

export const switchDistanceBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/switch-DistanceBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.switchDistanceBasedFee(values);
});

// Percentage based
export const getPercentageBasedFeeDataThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/get-all-percentageBasedFee-data", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getPercentageBasedFee();
  return response;
});

export const addPercentageBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/add-percentageBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.addPercentageBasedFee(values);
});

export const switchPercentageBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/switch-percentageBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.switchPercentageBasedFee(values);
});

// incremental based
export const getIncrementalBasedFeeDataThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/get-all-IncrementalBasedFee-data", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getIncrementalBasedFee();
  return response;
});

export const addIncrementalBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/add-IncrementalBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.addIncrementalBasedFee(values);
});

export const switchIncrementalBasedFeeDataThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/switch-IncrementalBased-fee", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.switchIncrementalBasedFee(values);
});

// Speed
export const getSpeedDataThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/speed-data", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getSpeedData();
  return response;
});

export const addMaxSpeedThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/max-speed", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.addMaxSpeed(values);
});

export const addNewRolesThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/addRoles", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.addNewRoles(values);
});

export const deleteRoleThunk = createAsyncThunk<any, any, { state: RootState }>(
  "users/deleteRoles",
  async (id: number) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    const response = await useCase.deleteRole(id);
    return response.data;
  }
);

export const getShipmentTrackingReportThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/getShipmentTrackingReport", async (orderNumber: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getShipmentTrackingReport(orderNumber);
  return response;
});

export const getShipmentCalenderReportThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/getShipmentCalendar", async (date: string) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getShipmentCalendarReport(date);
  return response?.data;
});

export const getShipmentDispatchReportThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("users/getShipmentCalendar", async ({ page, input, filter }) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getShipmentDispatchReport(page, input, filter);
  return response?.data;
});

export const getDashboardThunk = createAsyncThunk<
  any,
  {
    role:
      | "ROLE_VEHICLE_OWNER"
      | "ROLE_PLATFORM"
      | "ROLE_SHIPPER"
      | "ROLE_OTHER";
  }
>(
  "dashboard/getdashboard",
  async ({
    role,
  }: {
    role:
      | "ROLE_VEHICLE_OWNER"
      | "ROLE_PLATFORM"
      | "ROLE_SHIPPER"
      | "ROLE_OTHER";
  }) => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    return await useCase.getDashboard(role);
  }
);

export const getSkippedAdditionalInfoThunk = createAsyncThunk<any>(
  "users/skippedAdditional",
  async () => {
    const useCase = userModuleContainer.getProvided(UserUseCase);
    return await useCase.getSkippedAdditionalInfo();
  }
);

export const setEstimatedPriceRuleThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("users/setEstimatedPriceRule", async (values: any) => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  return await useCase.setEstimatedPriceRule(values);
});

export const getEstimatedPriceRuleThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/getEstimatedPriceRuleThunk", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.getEstimatedPriceRule();
  return response;
});

export const updateEstimatedPriceRuleThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("users/updateEstimatedPriceRuleThunk", async () => {
  const useCase = userModuleContainer.getProvided(UserUseCase);
  const response = await useCase.updateEstimatedPriceRule();
  return response;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getRefreshTokenThunk.fulfilled, (state, action) => {
      state.token = action?.payload?.accessToken;
      state.isLoggedIn = true;
    });
    builder.addCase(getRefreshTokenThunk.rejected, (state, action) => {
      state.isLoggedIn = false;
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(userLoginThunk.fulfilled, (state, action) => {
      const { success, data } = action?.payload?.data || {};
      if (!!success) {
        state.user = data;
      }
    });

    builder
      .addCase(getAllPlatformAdminThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.platformAdmin = action?.payload?.data?.data?.users;
        state.usersCount = action?.payload?.data?.data?.count;
      })
      .addCase(getAllPlatformAdminThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllPlatformAdminThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAPlatformAdminThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.platformAdminDetails = action?.payload?.data?.data;
      })
      .addCase(getAPlatformAdminThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deletePlatformAdminThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        console.log(action);
        state.platformAdmin = action?.payload?.data?.data;
      })
      .addCase(deletePlatformAdminThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(updatePlatformAdminThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePlatformAdminThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const updatedPlatformAdmin = action.payload;
        state.platformAdmin = state.platformAdmin.map(
          (newplatformAdmin: any) =>
            newplatformAdmin.id === updatedPlatformAdmin.id
              ? updatedPlatformAdmin
              : newplatformAdmin
        );
      })
      .addCase(updatePlatformAdminThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Update failed";
      });

    builder
      .addCase(getAllRolesListThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.rolesCount = action?.payload?.data?.data?.count;
        state.roles = action?.payload?.data?.data;
      })
      .addCase(getAllRolesListThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllRolesListThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllPermissionsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.permissions = action?.payload?.data?.data;
      })
      .addCase(getAllPermissionsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.error?.message || "Unknown error";
      })
      .addCase(getRolePermissionThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.rolePermission = action?.payload?.data;
      })
      .addCase(getRolePermissionThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.error?.message || "Unknown error";
      })
      .addCase(getRolePermissionThunk.pending, (state, action) => {
        state.isLoading = true;
      });
    builder.addCase(setUserPassword.fulfilled, (state, action) => {
      const { success, data } = action?.payload?.data || {};
      if (!!success) {
        state.user = data;
      }
    });

    builder.addCase(userSignUp.fulfilled, (state, action) => {
      const { success, data } = action?.payload || {};
      if (!!success) {
        state.user = data;
      }
    });
    builder.addCase(vehicleOwnerSignUp.fulfilled, (state, action) => {
      const { success, data } = action?.payload || {};
      if (!!success) {
        state.user = data;
      }
    });

    builder.addCase(updateUserThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action?.payload;
    });

    builder.addCase(userVerifyEmail.fulfilled, (state, action) => {
      const { success, data } = action?.payload?.data || {};
      if (!!success) {
        state.user = data;
      }
    });

    builder.addCase(sendCode.fulfilled, (state, action) => {
      const { success, data } = action?.payload?.data || {};
      if (!!success) {
        // state.user = data;
      }
    });

    builder.addCase(updateTokenThunk.fulfilled, (state, action: any) => {
      state.token = action?.payload?.accessToken;
    });

    builder.addCase(setIsLoggedIn, (state, action) => {
      state.isLoggedIn = action?.payload;
    });

    builder.addCase(getUserProfileThunk.fulfilled, (state, action) => {
      state.userProfile = action?.payload?.data;

      state.isLoggedIn = true;
    });

    builder.addCase(getUserProfileThunk.rejected, (state, action) => {
      console.error(action?.error?.message || "unknown error");
      state.isLoggedIn = true;
    });

    builder.addCase(updateUserProfileThunk.fulfilled, (state, action) => {
      state = action?.payload;
      state.isLoggedIn = true;
    });

    builder.addCase(updateUserProfileThunk.rejected, (state, action) => {
      state.isLoggedIn = true;
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(
      additonalInfoVehicleOwnerThunk.fulfilled,
      (state, action) => {
        state = action.payload;
        state.isLoggedIn = true;
      }
    );

    builder.addCase(
      additonalInfoVehicleOwnerThunk.rejected,
      (state, action) => {
        state.isLoggedIn = true;
        console.error(action.error.message || "unknown error");
      }
    );

    builder.addCase(getDashboardThunk.fulfilled, (state, action) => {
      state.dashboardData = action.payload?.data;
    });
    builder.addCase(getDashboardThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.token = "";
      state.user = {
        userId: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        contactNumber: 0,
        loginMethod: "",
        username: "",
        verificationCode: { value: 0 },
        accountStatus: "",
        roles: [],

        permissions: [],
        tcAgreed: false,
        address: {
          addressId: 0,
          streetAddress: "",
          administrativeDivision: {
            administrativeDivisionId: 0,
            state: "",
            district: "",
            municipality: "",
          },
        },
      };
    });

    builder.addCase(
      getAdministrativeDivisionThunk.fulfilled,
      (state, action) => {
        state.administrativeDivision = action?.payload?.data;
      }
    );
    builder.addCase(
      getAdministrativeDivisionThunk.rejected,
      (state, action) => {
        console.log(action.error.message || "unknown error");
      }
    );
    builder
      .addCase(getAllDeviceLocationThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        state.locations = action?.payload?.data?.data;
      })
      .addCase(getAllDeviceLocationThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getAllVechileOwnerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleOwner = action?.payload?.data?.data;
      })
      .addCase(getAllVechileOwnerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getNoOfVehiclesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.numberOfVehicles = action?.payload?.data?.data?.vehicles;
      })
      .addCase(getNoOfVehiclesThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(getAllNewVehicleOwnerThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllNewVehicleOwnerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        // Add to global state only if page !== 0 which returns limited data
        if (action.meta.arg.page !== 0) {
          state.vehicleOwner = action?.payload?.data?.data;
          state.vehicleOwnerCount = action?.payload?.data?.data?.count;
        }
      })
      .addCase(getAllNewVehicleOwnerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(getAVechileOwnerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleOwnerDetails = action?.payload?.data?.data;
      })
      .addCase(getAVechileOwnerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(getVehiclesOfVehicleOwnerThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getVehiclesOfVehicleOwnerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getVehiclesOfVehicleOwnerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })

      .addCase(deleteVechileOwnerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const vehicleOwnerId = action.meta.arg;
        state.vehicleOwner = state?.vehicleOwner?.vehicleOwners?.filter(
          (newvehicleOwner: { userId: any }) =>
            newvehicleOwner.userId !== vehicleOwnerId
        );
      })
      .addCase(deleteVechileOwnerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(updateVechileOwnerThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateVechileOwnerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const updatedVehicleOwner = action.payload;
        state.vehicleOwner = state.vehicleOwner?.vehicleOwners?.map(
          (newvehicleOwner: any) =>
            newvehicleOwner.id === updatedVehicleOwner.id
              ? updatedVehicleOwner
              : newvehicleOwner
        );
      })
      .addCase(updateVechileOwnerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Update failed";
      });
    builder
      .addCase(getAllDriverThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        // Add to global state only if page !==0 which returns limited data
        if (action.meta.arg.page !== 0) {
          state.driverCount = action?.payload?.data?.data?.count;
          state.drivers = action?.payload?.data?.data?.drivers;
        }
      })
      .addCase(getAllDriverThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllDriverThunk.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteDriverThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const driverId = action.meta.arg;
        state.drivers = state?.drivers?.filter(
          (driver: { driverId: any }) => driver.driverId !== driverId
        );
      })
      .addCase(deleteDriverThunk.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error =
          action.payload?.message || action.error.message || "Unknown error";
      });

    builder
      .addCase(updateDriverThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateDriverThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const updatedDriver = action.payload;
        state.drivers = state.drivers.map((driver: any) =>
          driver.id === updatedDriver.id ? updatedDriver : driver
        );
      })
      .addCase(updateDriverThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Update failed";
      });
    builder
      .addCase(getAllShipperThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shippers = action?.payload?.data?.data;
        state.shippersCount = action?.payload?.data?.data?.count;
      })
      .addCase(getAllShipperThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllShipperThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAShipperThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shippersDetails = action?.payload?.data?.data;
      })
      .addCase(getAShipperThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deleteShipperThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const shipperId = action.meta.arg;
        state.shippers = state?.shippers?.shippers?.filter(
          (shipper: { userId: any }) => shipper.userId !== shipperId
        );
      })
      .addCase(deleteShipperThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
    builder
      .addCase(getAllDriverDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.devices = action?.payload?.data?.data;
      })
      .addCase(getAllDriverDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
    builder
      .addCase(updateShipperThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateShipperThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const updatedShipper = action.payload;
        state.shippers = state?.shippers?.shipper?.map((shipper: any) =>
          shipper.id === updatedShipper.id ? updatedShipper : shipper
        );
      })
      .addCase(updateShipperThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Update failed";
      });

    builder.addCase(
      messageReceivedFromSocketThunk.fulfilled,
      (state, action) => {
        const socketData: any = action?.meta?.arg;
        const tripDetails = state?.devices;

        const tripToUpdate =
          tripDetails &&
          tripDetails.find(
            (trip: any) => trip?.trip?.tripId === socketData?.content?.tripId
          );

        if (
          tripToUpdate &&
          tripToUpdate.device[0] &&
          tripToUpdate.device[0].deviceLocation
        ) {
          const newCoordinates = socketData?.content?.location?.coordinates;

          tripToUpdate.device[0].deviceLocation.location.coordinates =
            newCoordinates;
          tripToUpdate.device[0].deviceLocation.created_at =
            socketData?.content?.updateAt;
        } else {
          console.log("Trip not found user.");
        }
      }
    );
    builder.addCase(
      messageReceivedFromSocketThunk.rejected,
      (state, action) => {
        console.error(action.error.message || "unknown error");
      }
    );

    // Transaction Fee

    // distance based
    builder
      .addCase(getAllDistanceBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.distanceBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.distanceBased;
      })
      .addCase(getAllDistanceBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllDistanceBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addDistanceBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.distanceBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.distanceBased;
      })
      .addCase(addDistanceBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addDistanceBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(editDistanceBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.distanceBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.distanceBased;
      })
      .addCase(editDistanceBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editDistanceBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(
        deleteAllDistanceBasedFeeDataThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.error = null;
          state.distanceBasedFee =
            action?.payload?.data?.data.transactionFeePolicies.distanceBased;
        }
      )
      .addCase(deleteAllDistanceBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(switchDistanceBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.distanceBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.distanceBased;
      })
      .addCase(switchDistanceBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(switchDistanceBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });

    //percentage based
    builder
      .addCase(getPercentageBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.percentageBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.percentageBased;
      })
      .addCase(getPercentageBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getPercentageBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPercentageBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.percentageBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.percentageBased;
      })
      .addCase(addPercentageBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(addPercentageBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(switchPercentageBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.percentageBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.percentageBased;
      })
      .addCase(switchPercentageBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(switchPercentageBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });

    // incremental based
    builder
      .addCase(getIncrementalBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.incrementalBasedFee =
          action?.payload?.data?.data.transactionFeePolicies.incrementalBased;
      })
      .addCase(getIncrementalBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getIncrementalBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addIncrementalBasedFeeDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        if (action?.payload?.data?.success) {
          state.incrementalBasedFee =
            action?.payload?.data?.data?.transactionFeePolicies
              ?.incrementalBased;
        }
      })
      .addCase(addIncrementalBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(addIncrementalBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        switchIncrementalBasedFeeDataThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.error = null;
          state.incrementalBasedFee =
            action?.payload?.data?.data.transactionFeePolicies.incrementalBased;
        }
      )
      .addCase(switchIncrementalBasedFeeDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(switchIncrementalBasedFeeDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });

    // vehicle type based
    // builder
    //   .addCase(
    //     getAllVehicleTypeBasedFeeDataThunk.fulfilled,
    //     (state, action) => {
    //       state.isLoading = false;
    //       state.error = null;
    //       state.vehicleTypeBasedFee =
    //         action?.payload?.data?.data.transactionFeePolicies
    //           ?.vehicleTypeBased;
    //       // console.log(action.payload.data.data.transactionFeePolicies);
    //     }
    //   )
    //   .addCase(getAllVehicleTypeBasedFeeDataThunk.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = action.error.message || "Unknown error";
    //   })
    //   .addCase(getAllVehicleTypeBasedFeeDataThunk.pending, (state) => {
    //     state.isLoading = true;
    //     state.error = null;
    //   });
    // builder
    //   .addCase(addVehicleTypeBasedFeeDataThunk.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.error = null;
    //     state.vehicleTypeBasedFee =
    //       action?.payload?.data?.data.transactionFeePolicies?.VehicleTypeBased;
    //   })
    //   .addCase(addVehicleTypeBasedFeeDataThunk.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = action.error.message || "Unknown error";
    //   })
    //   .addCase(addVehicleTypeBasedFeeDataThunk.pending, (state) => {
    //     state.isLoading = true;
    //     state.error = null;
    //   });
    builder
      .addCase(deleteVehicleTypeRuleThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleTypeBasedFee =
          action?.payload?.data?.data.transactionFeePolicies?.VehicleTypeBased;
      })
      .addCase(deleteVehicleTypeRuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deleteVehicleTypeRuleThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });

    builder
      .addCase(getShipmentDispatchReportThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shipmentDispatchReport = action?.payload;
      })
      .addCase(getShipmentDispatchReportThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getShipmentDispatchReportThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      });

    // Speed
    builder
      .addCase(getSpeedDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.speed = action?.payload?.data?.data;
      })
      .addCase(getSpeedDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
    builder
      .addCase(addMaxSpeedThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.speed = action?.payload?.data?.data;
      })
      .addCase(addMaxSpeedThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    //hash key
    builder
      .addCase(getHashKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.hashKey = action?.payload?.data;
      })
      .addCase(getHashKey.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(generateNewHashKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.hashKey = action?.payload?.data;
      })
      .addCase(generateNewHashKey.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getDigitalContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        state.digitalContract = action?.payload?.data;
      })
      .addCase(getDigitalContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(generateDigitalContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.digitalContract = action?.payload?.data?.data;
      })
      .addCase(generateDigitalContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder.addCase(updateAuthorizedBidThunk.fulfilled, (state, action) => {
      if (!!action.payload.success) {
        const updatedVehicle = action.payload.data;

        const index = state.drivers.findIndex(
          (driver: any) =>
            String(driver?.driverId) ===
            String(updatedVehicle?.driver?.driverId)
        );
        // If the vehicle is found, update its information
        if (index !== -1) {
          state.drivers[index].bidAuthority =
            updatedVehicle.driver.bidAuthority;
        }
      }
    });

    //delete role
    builder
      .addCase(deleteRoleThunk.fulfilled, (state, action) => {
        if (action?.payload?.success || action?.payload?.data?.success) {
          state.roles = action?.payload;
        }
      })
      .addCase(deleteRoleThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder.addCase(showHeader, (state, action) => {
      state.showHeader = action?.payload;
    });

    //estimated price rule
    builder
      .addCase(getEstimatedPriceRuleThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.estimatedRule = action?.payload?.data;
      })
      .addCase(getEstimatedPriceRuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(updateEstimatedPriceRuleThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.estimatedRule = action?.payload?.data;
      })
      .addCase(updateEstimatedPriceRuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });
  },
});

export const userSelectors = {};

export const userActions = userSlice.actions;

export const userThunks = {
  userLoginThunk,
  userSignUp,
  vehicleOwnerSignUp,
  acceptTermsAndCondition,
  userVerifyEmail,
  verifyNumbrThunk,
  setUserPassword,
  updateAuthenticationMethod,
  setFirstLogin,
  setIsLoggedIn,
  userLoginWitPasswordThunk,
  getRefreshTokenThunk,
  updatePassword,
  reverseGeoCode,
  createPlatformAdminThunk,
  getUserProfileThunk,
  updateUserProfileThunk,
  additonalInfoVehicleOwnerThunk,
  getAdministrativeDivisionThunk,
  verifyUsername,
  resetPassword,
  userLogout,
  getShipperThunk,
  sendCode,
  getAllPlatformAdminThunk,
  getAllVechileOwnerThunk,
  getAllNewVehicleOwnerThunk,
  createVechileOwnerThunk,
  getAVechileOwnerThunk,
  getVehiclesOfVehicleOwnerThunk,
  deleteVechileOwnerThunk,
  updateVechileOwnerThunk,
  getAllDriverThunk,
  createDriverThunk,
  getADriverThunk,
  getNoOfVehiclesThunk,
  deleteDriverThunk,
  updateDriverThunk,
  createShipperThunk,
  getAllShipperThunk,
  getAShipperThunk,
  deleteShipperThunk,
  updateShipperThunk,
  getAllDeviceLocationThunk,
  getAllRolesListThunk,
  getAllDriverDevices,
  getAllPermissionsThunk,
  messageReceivedFromSocketThunk,
  getRolePermissionThunk,
  assignPermissionsThunk,
  deletePlatformAdminThunk,
  updatePlatformAdminThunk,
  getAPlatformAdminThunk,
  getHashKey,
  generateNewHashKey,
  getDigitalContract,
  generateDigitalContract,
  updateAuthorizedBidThunk,
  getDashboardThunk,
  getSpeedDataThunk,
  addMaxSpeedThunk,
  getShipmentTrackingReportThunk,
  getShipmentCalenderReportThunk,
  getShipmentDispatchReportThunk,
  addNewRolesThunk,
  deleteRoleThunk,
  setAuthUserPasswordThunk,
  acceptTermsAndConditionWithoutToken,
  showHeader,
  getSkippedAdditionalInfoThunk,
  getAllDistanceBasedFeeDataThunk,
  addDistanceBasedFeeDataThunk,
  editDistanceBasedFeeDataThunk,
  deleteAllDistanceBasedFeeDataThunk,
  switchDistanceBasedFeeDataThunk,
  setEstimatedPriceRuleThunk,
  getEstimatedPriceRuleThunk,
  getAllVehicleTypeBasedFeeDataThunk,
  updateVehicleTypeBasedFeeDataThunk,
  deleteVehicleTypeRuleThunk,
  getPercentageBasedFeeDataThunk,
  addPercentageBasedFeeDataThunk,
  switchPercentageBasedFeeDataThunk,
  addIncrementalBasedFeeDataThunk,
  getIncrementalBasedFeeDataThunk,
  switchIncrementalBasedFeeDataThunk,
  updateEstimatedPriceRuleThunk,
};

export const userReducer = userSlice.reducer;
