import React, { lazy } from "react";
import { PublicRoute } from "../../../core/presentation/navigation/types/route";
const restrictedUserRoutes = [
  {
    path: "/",
    Component: lazy(() => import("../pages/Dashboard")),
    permission: ["*"],
  },
  {
    path: "/user-profile",
    Component: lazy(() => import("../pages/ProfileInformation")),
    permission: ["*"],
  },
  {
    path: "/profile",
    Component: lazy(() => import("../pages/UserProfile/ProfilePage")),
    permission: ["*"],
  },
  {
    path: "/additional-information",
    Component: lazy(
      () => import("../components/AdditionalInformation/AdditionalInfoParent")
    ),
    role: "shipper",
    permission: ["profile:update"],
  },
  {
    path: "/additional-information-vehicle-owner",
    Component: lazy(
      () =>
        import(
          "../components/AdditionalInformationVehicleOwner/AdditionalInfoParent"
        )
    ),
    role: "vehicle_owner",
    permission: ["profile:update"],
    // permission: ["vehicle_owner:update"],
  },
  {
    path: "/settings",
    Component: lazy(() => import("../pages/Setting")),
    permission: ["*"],
  },
  {
    path: "/users",
    Component: lazy(() => import("../pages/PlatformAdmin")),
    permission: ["user:list_all"],
  },
  {
    path: "/users/create",
    Component: lazy(() => import("../components/PlatformAdmin/create")),
    permission: ["user:create"],
  },
  {
    path: "/users/edit/:id",
    Component: lazy(() => import("../components/PlatformAdmin/edit")),
    permission: ["user:update"],
  },
  {
    path: "/users/details/:id",
    Component: lazy(() => import("../components/PlatformAdmin/view")),
    permission: ["user:delete"],
  },
  {
    path: "/vehicle-owner-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/VehicleOwnerReport")
    ),
    permission: ["*"],
  },
  {
    path: "/driver-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/DriverReport")
    ),
    permission: ["*"],
  },
  {
    path: "/vehicle-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/VehicleReport")
    ),
    permission: ["*"],
  },
  {
    path: "/expiry-report",
    Component: lazy(
      () => import("../components/VehicleOwner/reports/ExpiryReport")
    ),
    permission: ["*"],
  },
  {
    path: "/vehicle-history",
    Component: lazy(
      () => import("../components/VehicleOwner/reports/VehicleLogHistory")
    ),
    permission: ["*"],
  },
  {
    path: "/GPS-report",
    Component: lazy(
      () => import("../components/VehicleOwner/reports/GPSReport")
    ),
    permission: ["*"],
  },
  {
    path: "/trip-report",
    Component: lazy(
      () => import("../components/VehicleOwner/reports/TripReports")
    ),
    permission: ["*"],
  },
  {
    path: "/driver-reports",
    Component: lazy(
      () => import("../components/VehicleOwner/reports/DriverReports")
    ),
    permission: ["*"],
  },
  {
    path: "/vehicle-Checklist-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/VehicleChecklist")
    ),
    permission: ["*"],
  },
  {
    path: "/vehicle-Checklist-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/VehicleChecklist")
    ),
    permission: ["*"],
  },
  {
    path: "/driver",
    Component: lazy(() => import("../components/Driver/ListDriver/ListDriver")),
    permission: ["driver:list", "driver:list_all"],
  },
  {
    path: "/driver/create",
    Component: lazy(
      () => import("../components/Driver/CreateEditDriver/CreateDriver")
    ),
    permission: ["driver:create"],
  },
  {
    path: "/driver/edit/:id",
    Component: lazy(
      () => import("../components/Driver/CreateEditDriver/EditDriver")
    ),
    permission: ["driver:update"],
  },
  {
    path: "/tqms-vehicle-owner",
    Component: lazy(() => import("../components/TQMS/VehicleOwner")),
    permission: ["tqms:list_all"],
  },
  {
    path: "/tqms-pending-vehicle-all",
    Component: lazy(() => import("../components/TQMS/PendingVehicle")),
    permission: ["tqms:list_all"],
  },
  {
    path: "/tqms-vehicle",
    Component: lazy(() => import("../components/TQMS/Vehicle")),
    permission: ["*"],
  },
  {
    path: "/tqms-vehicle-list",
    Component: lazy(() => import("../components/TQMS/VehicleOfVehicleOwner")),
    permission: ["*"],
  },
  {
    path: "/vehicle-details/:id",
    Component: lazy(() => import("../components/TQMS/VehilceDetails")),
    permission: ["*"],
  },
  {
    path: "/rejected-vehicle",
    Component: lazy(() => import("../components/TQMS/RejectedVehicle")),
    permission: ["*"],
  },
  {
    path: "/tqms-driver",
    Component: lazy(() => import("../components/TQMS/Driver")),
    permission: ["tqms:list_all"],
  },
  {
    path: "/driver/details/:id",
    Component: lazy(() => import("../components/Driver/DriverDetails/index")),
    permission: ["driver:detail"],
  },
  {
    path: "/shipper",
    Component: lazy(
      () => import("../components/Shipper/ListShipper/ListShipper")
    ),
    permission: ["shipper:list_all"],
  },
  {
    path: "/shipper/create",
    Component: lazy(
      () => import("../components/Shipper/CreateEditShipper/CreateShipper")
    ),
    permission: ["shipper:create"],
  },
  {
    path: "/shipper/edit/:id",
    Component: lazy(
      () => import("../components/Shipper/CreateEditShipper/EditShipper")
    ),
    permission: ["shipper:update"],
  },
  {
    path: "/shipper/details/:id",
    Component: lazy(() => import("../components/Shipper/ShipperDetails/index")),
    permission: ["shipper:detail"],
  },
  {
    path: "/vehicle-owner",
    Component: lazy(() => import("../pages/VehicleOwner")),
    permission: ["vehicle_owner:list_all"],
  },
  {
    path: "/live-tracking",
    Component: lazy(() => import("../pages/LiveTracking/index")),
    permission: ["*"],
  },
  // {
  //   path: "/live-tracking",
  //   Component: lazy(
  //     () => import("../components/VehicleOwner/VehicleOwnerDetails/index")
  //   ),
  //   permission: ["*"],
  // },
  {
    path: "/roles",
    Component: lazy(() => import("../components/Roles&Permissions/RolesList")),
    permission: ["*"],
  },
  {
    path: "/roles/create",
    Component: lazy(() => import("../components/Roles&Permissions/Create")),
    permission: ["role:create"],
  },
  {
    path: "/roles/permissions/:id",
    Component: lazy(
      () => import("../components/Roles&Permissions/RolesPermissions")
    ),
    permission: ["*"],
  },
  {
    path: "/vehicle-owner/create",
    Component: lazy(
      () =>
        import(
          "../components/VehicleOwner/CreateEditVehicleOwner/CreateVehicleOwner"
        )
    ),
    permission: ["vehicle_owner:create"],
  },
  {
    path: "/vehicle-owner/edit/:id",
    Component: lazy(
      () =>
        import(
          "../components/VehicleOwner/CreateEditVehicleOwner/EditVehicleOwner"
        )
    ),
    permission: ["vehicle_owner:update"],
  },
  {
    path: "/vehicle-owner/details/:id",
    Component: lazy(
      () => import("../components/VehicleOwner/VehicleOwnerDetails/index")
    ),
    permission: ["vehicle_owner:detail"],
  },
  {
    path: "/digital-contract",
    Component: lazy(() => import("../pages/DigitalContract/index")),
    permission: ["*"],
  },
  {
    path: "/add/digital-contract",
    Component: lazy(
      () => import("../pages/DigitalContract/AddDigitalContract")
    ),
    permission: ["*"],
  },
  {
    path: "/declined-vehicle-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/DeclinedVehiclesReport")
    ),
    permission: ["*"],
  },
  {
    path: "/edit-request-vehicle-report",
    Component: lazy(
      () =>
        import("../components/PlatformAdmin/reports/EditRequestVehicleReport")
    ),
    permission: ["*"],
  },
  {
    path: "/awating-approval-vehicle-report",
    Component: lazy(
      () =>
        import(
          "../components/PlatformAdmin/reports/AwatingApprovalVehicleReport"
        )
    ),
    permission: ["*"],
  },
  {
    path: "/server-logs-report",
    Component: lazy(
      () => import("../components/PlatformAdmin/reports/ServerLogsReport")
    ),
    permission: ["*"],
  },
  {
    path: "/shipment-tracking-report",
    Component: lazy(
      () =>
        import(
          "../components/PlatformAdmin/reports/ShipmentReport/ShipmentTrackingReport"
        )
    ),
    permission: ["*"],
  },
  {
    path: "/shipment-calender",
    Component: lazy(
      () =>
        import(
          "../components/PlatformAdmin/reports/ShipmentReport/ShipmentCalenderReport/ShipmentCalender"
        )
    ),
    permission: ["*"],
  },
  {
    path: "/shipment-dispatch",
    Component: lazy(
      () =>
        import(
          "../components/PlatformAdmin/reports/ShipmentReport/ShipmentDispatch"
        )
    ),
    permission: ["*"],
  },
  {
    path: "/approval",
    Component: lazy(() => import("../pages/Approval/Approval")),
    permission: ["*"],
  },
  {
    path: "*",
    Component: lazy(() => import("../../../core/presentation/pages/NotFound")),
    permission: ["*"],
  },
];
export default restrictedUserRoutes;
