import { IUserRepository } from "../business/IUserRepository";
import User, {
  VehicleTypeBasedInterface,
  VehicleTypeFeeRule,
} from "../business/User";
import ApiService from "../../core/presentation/services/ApiService";
import { injectable } from "inversify-sugar";
import { VehicleInfo } from "fleet/business/Fleet";

@injectable()
class UserRepository implements IUserRepository {
  public async acceptTerms(): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost("auth/agree-terms", "");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getAllDriverDevices(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("device/drivers", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllRolesList(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `roles/created?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `roles/created${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllPermissionsList(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("roles/permissions", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async assignPermissions(values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `roles/assign-permissions`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getRolePermission(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`roles/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllDeviceLocation(tripId: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "/device/location",
        tripId
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async reverseGeoCode(values: any): Promise<any> {
    try {
      const response = await ApiService.setPost(
        "device/reverse-geocode",
        values
      );
      return response || {};
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async setPassword(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost("auth/password", values);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setAuthUserPassword(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost(
        "auth/user-password",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateAuthMethod(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.updatePost(
        "auth/login-method",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setIsFirstLogin(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.getPost("users/firstLogin", "");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async logout(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `/users/log-out`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getRefreshToken(): Promise<User | any> {
    try {
      const response: any = await ApiService.getPost("auth/refresh", "");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createOtp(values: any): Promise<User> {
    const response: any = await ApiService.setPost("auth/createotp", values);

    return response?.data || {};
  }

  public async verifyNumber(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost(
        "auth/verify-code",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async resetPassword(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost(
        "auth/reset-password",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async verifyUsername(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost(
        "auth/verify-reset-password",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendCode(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost("auth/send-code", values);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loginWithPassword(values: any): Promise<any> {
    try {
      const response = await ApiService.setPost("auth/login", values);
      return response || {};
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async signup(values: any): Promise<User> {
    try {
      const response: any = await ApiService.setPost(
        "auth/shipper-signup",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async vehicleOwnerSignup(values: any): Promise<User> {
    try {
      const response: any = await ApiService.setPost(
        "auth/vehicle-owner-signup",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async login(values: any): Promise<any> {
    try {
      const response = await ApiService.setPost("auth/federate", values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async verifyEmail(values: any): Promise<any> {
    try {
      const response = await ApiService.setPost("auth/verify-email", values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async createPlatformAdmin(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("users/new-user", values);
      return response.data;
    } catch (error) {
      return error;
      // Promise.reject(error);
    }
  }

  public async getAllPlatformAdmin(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/users/new-user?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `users/new-user${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAPlatformAdmin(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `users/platform-admin/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deletePlatformAdmin(id: any): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `users/new-user/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updatePlatformAdmin(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `users/new-user/${id}`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  // Userr Profile
  public async getUserProfile(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("auth/profile", "");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async additonalInfoVehicleOwner(
    values: any,
    id: number
  ): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `users/additional-information/${id}`,
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateUserProfile(values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        "/auth/shippers",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getAdministrativeDivision(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "public/administrative-divisions",
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async updatePassword(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.updatePost(
        "auth/password",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getShipper(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("auth/shippers", "");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // Users
  // Vehicle Owner
  public async getAllVechileOwner(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "auth/vehicle-owners?vehicleCount=false",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllNewVechileOwner(
    page: number,
    input: string,
    filter: string,
    report: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/auth/vehicle-owners?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}${report}`
          : `auth/vehicle-owners${filter ? `?${filter}` : ""}${report}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getNoOfVehicles(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/vehicle/vehicle-number`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createVechileOwner(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("users", values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getVehicleTypeBasedRule(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`rule/vehicle-type`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateVehicleTypeBasedRule(value: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `rule/vehicle-type`,
        value
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteVehicleTypeRule(id: number): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `rule/vehicle-type/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async addDistanceBasedFee(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "rule/distance-based",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async editDistanceBasedFee(values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `rule/distance-based`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteAllDistanceBasedFee(): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `rule/distance-based`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async switchDistanceBasedFee(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(`rule/status`, value);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllDistanceBasedFee(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`rule/distance-based`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getPercentageBasedFee(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `rule/percentage-based`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async addPercentageBasedFee(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        `rule/percentage-based`,
        value
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async switchPercentageBasedFee(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(`rule/status`, value);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  // incremental based

  public async getIncrementalBasedFee(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `rule/incremental-based`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async addIncrementalBasedFee(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        `rule/incremental-based`,
        value
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async switchIncrementalBasedFee(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(`rule/status`, value);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async addMaxSpeed(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "/vehicle/speed-limit",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getSpeedData(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`vehicle/speed-limit`, "");

      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAVechileOwner(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`users/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getVehiclesOfVehicleOwner(
    contactNumber: number,
    page: number
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `vehicle/get-vehicle?contactNumber=${contactNumber}&page=${page}&limit=10`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteVechileOwner(id: any): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(`users/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateVechileOwner(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(`users/${id}`, values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  // Driver
  public async getAllDriver(
    page: number,
    input: string,
    filter: string,
    report: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `users/drivers?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}${report}`
          : `/users/drivers${filter ? `?${filter}` : ""}${report}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getADriver(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`users/driver/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createDriver(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("users/driver", values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteDriver(id: any): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `users/driver/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateDriver(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `users/driver/${id}`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllShipper(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const roleId = 1;
      const response: any = await ApiService.getPost(
        page > 0
          ? `/users/shipper/list?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `users/shipper/list${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAShipper(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`users/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createShipper(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("users", values);
      return response;
    } catch (error) {
      return error;
      // Promise.reject(error);
    }
  }

  public async deleteShipper(id: any): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(`users/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateShipper(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(`users/${id}`, values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  // Hash Key
  public async getHashKey(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("auth/tqms-hash", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async generateNewHash(): Promise<any> {
    try {
      const response: any = await ApiService.setPost("auth/tqms-hash", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  // Dgital Contract Key
  public async getDigitalContract(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/digital-contract",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async generateDigitalContract(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "order/digital-contract",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async assignDriver(values: any): Promise<VehicleInfo> {
    try {
      const response: any = await ApiService.setPost(
        "vehicle/assign-driver",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getDashboard(
    role: "ROLE_VEHICLE_OWNER" | "ROLE_PLATFORM" | "ROLE_SHIPPER" | "ROLE_OTHER"
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `dashboard${
          role === "ROLE_VEHICLE_OWNER"
            ? "/vehicle-owner"
            : role === "ROLE_PLATFORM"
              ? "/platform-admin"
              : role === "ROLE_SHIPPER"
                ? "/shipper"
                : ""
        }`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async addNewRoles(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("roles", values);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteRole(id: number): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(`roles/${id}`, "");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getShipmentTrackingReport(trackingNumber: any): Promise<any> {
    try {
      const response = await ApiService.getPost(
        `order/shipper/report/v2?search=${trackingNumber}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getShipmentCalendarReport(date: string): Promise<any> {
    try {
      const response = await ApiService.getPost(
        `order/shipper/calendar-report?orderDate=${date}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getShipmentDispatchReport(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      let url = `order/shipper/dispatch-report/v2`;

      if (page > 0) {
        url += `?page=${page}&limit=10`;
      }

      if (input) {
        url += `${page > 0 ? "&" : "?"}search=${input}`;
      }

      if (filter) {
        url += `${page > 0 || input ? "&" : "?"}${filter}`;
      }

      const response: any = await ApiService.getPost(url, "");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async acceptTermsWithoutToken(values: any): Promise<User | any> {
    try {
      const response: any = await ApiService.setPost(
        "auth/user-tc-agree",
        values
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //skipped additional information
  public async getSkippedAdditionalInfo(): Promise<User> {
    try {
      const response: any = await ApiService.getPost(
        "users/skip-additional-info",
        ""
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async setEstimatedPriceRule(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "order/estimate-price",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async getEstimatedPriceRule(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/estimate-price",
        ""
      );
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async updateEstimatedPriceRule(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/apply-estimated-fare",
        ""
      );
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default UserRepository;
