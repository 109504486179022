import React, { Suspense, useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, restrictedRoutes } from "../../config/RouteConfig";
import Loader from "../../components/Loader";
import { PublicRoute } from "./route";
import { useAppDispatch, useAppSelector } from "core/presentation/store/hooks";
import MainLayout from "core/presentation/layout/MainLayout";
import { jwtDecode } from "jwt-decode";
import BgWrapper from "../../components/BgWrapper";

import { JwtTokens } from "user/business/User";
import {
  getCurrentUserDetails,
  getRoleCode,
  hasPermission,
} from "core/presentation/utils/Helper";
import NotFound from "core/presentation/pages/NotFound";
import { Modal, Spin } from "antd";
import { pushNotification } from "notifications/infrastructure/Notification";
import { AuthStorage } from "core/presentation/services/AuthTokenService";
import { userThunks } from "user/presentation/store/userSlice";
import TermsandConditions from "user/presentation/components/TermsandConditions/TermsandConditions";
import { layerGroup } from "leaflet";

export default function RootNavigator() {
  const [showTermsModal, setShowTermsModal] = useState(false);

  const token = localStorage.getItem("accessToken");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const isMounted = useRef(true);
  const location = useLocation();
  const [renderSidebar, setRenderSidebar] = useState<boolean>(true);
  const roleCodeToken = getRoleCode();
  let decodedToken: JwtTokens | null = null;
  if (token) {
    decodedToken = jwtDecode(token);
  }
  const { userProfile, isLoggedIn } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (token && !AuthStorage.isTokenExpired(token)) {
      pushNotification();
    }
  }, [token]);

  const createdRole = _.filter(userProfile?.user?.roles, { systemDefault: 0 });

  const isCreatedRole = createdRole.length > 0;

  const isAdditionalInfoSkipped: boolean =
    userProfile?.user?.isAdditionalInfoSkipped;

  useEffect(() => {
    if (isLoggedIn && !!!userProfile?.user?.tcAggreed) {
      setShowTermsModal(true);
      return;
    }
  }, [userProfile]);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (
          roleCodeToken === "VEHICLE_OWNER"
            ? token
            : token && decodedToken && decodedToken.scope.includes("profile")
        ) {
          const userDetails: any = await getCurrentUserDetails(dispatch);
          const roleCode = userDetails?.user?.roles[0].roleCode;
          const address = userDetails?.user?.address;

          if (isLoggedIn && !!!userDetails?.user?.tcAggreed) {
            setShowTermsModal(true);
            return;
          }

          if (
            roleCode == "SHIPPER" &&
            address === null &&
            !userDetails?.user?.isAdditionalInfoSkipped
          ) {
            navigate("/additional-information");
          }
          if (
            roleCode == "VEHICLE_OWNER" &&
            !userDetails?.userAddress?.streetAddress &&
            !!!userDetails?.user?.isAdditionalInfoSkipped &&
            !userDetails?.user?.isFirstLogin
          ) {
            navigate("/additional-information-vehicle-owner");
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();

    // return () => {
    //   isMounted.current = false;
    // };
    if (!userProfile) {
      userProfile && fetchUserDetails();
    }
  }, [dispatch, isAdditionalInfoSkipped]);

  useEffect(() => {
    if (
      location.pathname === "/additional-information" ||
      location.pathname === "/additional-information-vehicle-owner"
    ) {
      setRenderSidebar(false);
    } else {
      setRenderSidebar(true);
    }
  }, [location]);

  // useEffect(() => {
  //   const hasTokenExpired = !!token && AuthStorage.isTokenExpired(token);
  //   if (!hasTokenExpired) {
  //     dispatch(userThunks.getUserProfileThunk());
  //   }
  // }, [token, dispatch]);

  if (!userProfile) {
    return <Spin size="small" />;
  }

  const HaveBackgroundColor = location.pathname !== "/";
  return (
    <div>
      {(
        roleCodeToken === "VEHICLE_OWNER"
          ? token
          : token && decodedToken && decodedToken.scope.includes("profile")
      ) ? (
        <MainLayout renderSidebar={renderSidebar}>
          <BgWrapper HaveBackgroundColor={HaveBackgroundColor}>
            <Routes>
              {restrictedRoutes.map(({ path, Component, permission }) => (
                <>
                  <Route
                    key={path}
                    path={path}
                    element={
                      hasPermission(userProfile, permission) ? (
                        <Suspense fallback={<Loader />}>
                          {path === "/additional-information" ||
                          "/additional-information-vehicle-owner" ? (
                            <Component />
                          ) : (
                            <Component />
                          )}
                        </Suspense>
                      ) : (
                        <Loader />
                      )
                    }
                  />
                </>
              ))}
            </Routes>
          </BgWrapper>
        </MainLayout>
      ) : (
        <Routes>
          {publicRoutes.map(({ path, Component }: PublicRoute) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Routes>
      )}
      <Modal
        open={showTermsModal}
        onCancel={() => setShowTermsModal(false)}
        footer={null}
        centered
        closeIcon={null}
        width="70vw"
      >
        <TermsandConditions
          onClose={() => setShowTermsModal(false)}
          onSuccess={() => {
            setShowTermsModal(false);
          }}
        />
      </Modal>
    </div>
  );
}
